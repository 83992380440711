// const baseUrlORS = 'http://localhost:8080/ors';
const baseUrlORS = "https://api.openrouteservice.org";

export async function ConvertTextToCoordinates(text) {
  // check if text is already coordinates. If so, convert to floats manually.
  // if not, geocode the text.
  const coordinateRegEx = /(-)?\d+(\.?\d+)?/g;
  let coords;
  const matches = text.match(coordinateRegEx);
  if (matches && matches.length === 2) {
    // convert coords to float and reverse from lat, lon -> lon, lat
    coords = matches.map((str) => parseFloat(str)).reverse();
  } else {
    // geocode the text
    const url =
      `https://api.openrouteservice.org/geocode/search?` +
      `api_key=5b3ce3597851110001cf624861b120981ab54e5fad5f5e5299f407e1&` +
      `text=${text}&` +
      `boundary.country=GB`;
    const response = await fetch(url);
    try {
      const data = await response.json();
      if (data.features.length > 0) {
        coords = data.features[0].geometry.coordinates;
      } else {
        coords = null;
      }
      return coords;
    } catch (error) {
      console.log(
        "error occurred converting text to coords, disabling feature for 24 hours..."
      );
      return "disable";
    }
  }
}

export async function ConvertCoordinatesToText(lon, lat) {
  const url =
    `https://api.openrouteservice.org/geocode/reverse?` +
    `api_key=5b3ce3597851110001cf624861b120981ab54e5fad5f5e5299f407e1&` +
    `point.lon=${lon}&point.lat=${lat}&` +
    `boundary.country=GB`;

  const response = await fetch(url);
  const data = await response.json();
  const address = await data.features[0].properties.label;

  return address;
}

async function FetchRoute(startLatLon, endLatLon) {
  const url =
    `${baseUrlORS}/v2/directions/driving-car?` +
    `api_key=5b3ce3597851110001cf624861b120981ab54e5fad5f5e5299f407e1&` +
    `start=${startLatLon[0]},${startLatLon[1]}&` +
    `end=${endLatLon[0]},${endLatLon[1]}`;

  let response;
  try {
    response = await fetch(url);
    if (!response.ok) {
      // if we get a response but it is a bad response
      alert(
        "Error getting route information. Please ensure addresses are in the UK."
      );
      return null;
    } else {
      const route = await response.json();
      return route;
    }
  } catch (error) {
    // if the server is down
    if (error.message.includes("Failed to fetch")) {
      // alert('We are experiencing issues with the navigation feature. Please try again later.');
      console.log("Failed to fetch - disable search fields");
    }
  }
}

export async function SubmitRoute(routeStart, routeEnd) {
  const startLatLon = await ConvertTextToCoordinates(routeStart);
  const endLatLon = await ConvertTextToCoordinates(routeEnd);
  // fetch route
  let route;
  if (startLatLon && endLatLon) {
    route = await FetchRoute(startLatLon, endLatLon);
  } else {
    route = null;
  }
  return route;
}

export async function AutoFill(text) {
  const url =
    `https://api.openrouteservice.org/geocode/autocomplete?` +
    `api_key=5b3ce3597851110001cf624861b120981ab54e5fad5f5e5299f407e1&` +
    `text=${text}&` +
    `boundary.country=GB`;

  try {
    const response = await fetch(url);
    const suggestions = await response.json();
    let allSuggestions = [];
    for (const suggestion of suggestions.features) {
      allSuggestions.push(suggestion.properties.label);
    }
    return allSuggestions;
  } catch (error) {
    console.log(
      "error occurred fetching suggestions, disabling feature for 24 hours..."
    );
    return "disable";
  }
}

export async function GetDistanceToDestination(startLonLat, endLonLat) {
  try {
    const route = await FetchRoute(startLonLat, endLonLat);
    const distance = route.features[0].properties.segments[0].distance;
    return distance;
  } catch (err) {
    const distance = "Unknown";
    return distance;
  }
}
