import { useState, useEffect } from "react";
import { IoLocationSharp } from "react-icons/io5";
import { FaCopy } from "react-icons/fa";
import { Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";

import {
  restaurantLogosMap,
  shopsLogosMap,
  stationsLogoMap,
} from "../constants/logosMap";
import { GetDistanceToDestination } from "../services/openRouteService.js";

function StationMarker({ station, startLatLon }) {
  const map = useMap();

  const [stationDistance, setStationDistance] = useState(null);

  const restaurantLogos = station.restaurants.map(
    (restaurant) =>
      restaurantLogosMap[restaurant] && (
        <img
          className="company-logo"
          src={restaurantLogosMap[restaurant]}
          alt={restaurant}
        />
      )
  );

  const shopLogos = station.shops.map(
    (shop) =>
      shopsLogosMap[shop] && (
        <img className="company-logo" src={shopsLogosMap[shop]} alt={shop} />
      )
  );

  // calculate distance between start lonlat and station
  useEffect(() => {
    const GetDistanceToStation = async () => {
      if (startLatLon.length > 0) {
        let distance, distanceMiles;
        if (station.name !== "Dover Port") {
          // distance = await GetDistanceToDestination(startLatLon, [station.longitude, station.latitude]);
          // distanceMiles = (0.621371*distance/1000.).toFixed(0)
          // setStationDistance(distanceMiles);
        } else {
          setStationDistance(null);
        }
      }
    };
    GetDistanceToStation();
  }, [startLatLon, station]);

  // copy address to clipboard
  const copyToClipboard = async () => {
    navigator.clipboard
      .writeText([station.latitude, station.longitude])
      .then(() => {
        // alert('Copied to clipboard!');
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  // autocenter clicked station
  const AutoPanToMarker = () => {
    const bounds = map.getBounds();
    const centerLatOffset = (bounds.getNorth() - bounds.getSouth()) * 0.25;

    map.flyTo(
      [station.latitude + centerLatOffset, station.longitude],
      map.getZoom()
    );
  };

  return (
    <Marker
      position={[station.latitude, station.longitude]}
      icon={L.divIcon({
        className: "map-marker",
        html: ReactDOMServer.renderToString(<IoLocationSharp />),
      })}
      eventHandlers={{
        click: AutoPanToMarker,
      }}
    >
      <Popup>
        <div>
          <img
            className="station-logo"
            src={stationsLogoMap[station.provider]}
            alt={station.provider}
          />
        </div>

        <b>{station.name}</b>
        {stationDistance && `, ${stationDistance} miles away.`}

        <br />
        <button className="copy-address-button" onClick={copyToClipboard}>
          Copy address <FaCopy />
        </button>
        <div>{restaurantLogos.concat(shopLogos)}</div>
        {/* <ul>
                    {station.restaurants.map((restaurant, index) => (
                        <li key={index}>{restaurant}</li>
                    ))}
                </ul>
                <b>shops</b>
                <ul>
                    {station.shops.map((shop, index) => (
                        <li key={index}>{shop}</li>
                    ))}
                </ul> */}
      </Popup>
    </Marker>
  );
}

export default StationMarker;
