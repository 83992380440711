import "./App.css";
import { useState, useEffect } from "react";

import BaseMap from "./components/map";
import SearchBox from "./components/searchBox";
import AdBanner from "./components/adBanner";
import OffcanvasMenu from "./components/offCanvasMenu";
import FeedbackModal from "./components/feedbackForm";

import stationSample from "./constants/station_sample.json";

function App() {
  const [routeStart, setRouteStart] = useState("");
  const [routeEnd, setRouteEnd] = useState("");
  const [stations, setStations] = useState([]);

  const [showFeedback, setShowFeedback] = useState(false);

  const handleFeedbackShow = () => setShowFeedback(true);
  const handleFeedbackClose = () => setShowFeedback(false);

  // Set stations data once when the component mounts
  useEffect(() => {
    setStations(stationSample);
  }, []);

  return (
    <div>
      <BaseMap
        position={[55, -2.0]}
        zoom={6}
        stations={stations}
        routeStart={routeStart}
        routeEnd={routeEnd}
      />
      <SearchBox setRouteStart={setRouteStart} setRouteEnd={setRouteEnd} />
      <OffcanvasMenu handleFeedbackShow={handleFeedbackShow} />
      <FeedbackModal show={showFeedback} handleClose={handleFeedbackClose} />
      {/* <AdBanner/> */}
    </div>
  );
}

export default App;
