import React, { useState } from "react";
import { Offcanvas, Button } from "react-bootstrap";
import { IoMenu } from "react-icons/io5";

import FeedbackButton from "../components/feedbackButton";
import StripeButton from "../components/stripeButton";

function OffcanvasMenu({ handleFeedbackShow }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Button className="off-canvas-button" onClick={handleShow}>
        <IoMenu className="off-canvas-button-icon" />
      </Button>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>ServiceMe</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="button-list">
          <FeedbackButton handleFeedbackShow={handleFeedbackShow} />
          <StripeButton />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default OffcanvasMenu;
