import burgerking from "./logos/burgerking.png";
import cocodimama from "./logos/cocodimama.png";
import chozennoodle from "./logos/chozennoodle.png";
import cornishbakery from "./logos/cornishbakery.png";
import costa from "./logos/costa.png";
import chopstix from "./logos/chopstix.png";
import chowasiankitchen from "./logos/chowasiankitchen.png";
import deepblue from "./logos/deepblue.png";
import elmexicana from "./logos/elmexicana.png";
import extra from "./logos/extra.png";
import freshfoodcafe from "./logos/freshfoodcafe.png";
import gardensquaredeli from "./logos/gardensquaredeli.png";
import germandonerkebab from "./logos/germandonerkebab.png";
import greggs from "./logos/greggs.png";
import jdwetherspoon from "./logos/jdwetherspoon.png";
import krispykreme from "./logos/krispykreme.png";
import kfc from "./logos/kfc.png";
import leon from "./logos/leon.png";
import mands from "./logos/mands.png";
import mcdonalds from "./logos/mcdonalds.png";
import motoway from "./logos/motoway.png";
import nandos from "./logos/nandos.png";
import pizzaexpress from "./logos/pizzaexpress.png";
import phatpasty from "./logos/phatpasty.png";
import pret from "./logos/pret.png";
import roadchef from "./logos/roadchef.png";
import starbucks from "./logos/starbucks.png";
import spar from "./logos/spar.png";
import subway from "./logos/subway.png";
import tacobell from "./logos/tacobell.png";
import tapori from "./logos/tapori.png";
import tortilla from "./logos/tortilla.png";
import thegoodbreakfast from "./logos/thegoodbreakfast.png";
import waitrose from "./logos/waitrose.png";
import warrensbakery from "./logos/warrensbakery.png";
import welcomebreak from "./logos/welcomebreak.png";
import westcornwallpastyco from "./logos/westcornwallpastyco.png";
import whsmith from "./logos/whsmith.png";

const restaurantLogosMap = {
  // 'Breakfast Kitchen', // leaving as this is RC own brand - no logo
  "Burger King": burgerking,
  Chopstix: chopstix,
  "Chow Asian Kitchen": chowasiankitchen,
  "Chozen Noodle": chozennoodle,
  "Coco Di Mama": cocodimama,
  Costa: costa,
  "Deep Blue Fish & Chips": deepblue,
  "El Mexicana": elmexicana,
  "Fresh Food Cafe": freshfoodcafe,
  "Garden Square Deli": gardensquaredeli,
  "German Doner Kebab": germandonerkebab,
  Greggs: greggs,
  "JD Wetherspoon": jdwetherspoon,
  KFC: kfc,
  "Krispy Kreme": krispykreme,
  LEON: leon,
  McDonalds: mcdonalds,
  Nandos: nandos,
  "Pizza Express": pizzaexpress,
  "Phat Pasty": phatpasty,
  "Pret A Manger": pret,
  Starbucks: starbucks,
  Subway: subway,
  "Taco Bell": tacobell,
  "Tapori Indian Street Food": tapori,
  "The Cornish Bakery": cornishbakery,
  "The Good Breakfast": thegoodbreakfast,
  //  'The Pasty Shop',
  Tortilla: tortilla,
  "Warrens Bakery": warrensbakery,
  "West Cornwall Pasty": westcornwallpastyco,
};

const shopsLogosMap = {
  // 'GameZone',
  //  'Jackpot £500',
  "M&S": mands,
  //  'Quicksilver',
  Spar: spar,
  //  'Top Gift',
  //  'Urban Express',
  WHSmith: whsmith,
  "Waitrose & Partners": waitrose,
};

const stationsLogoMap = {
  Extra: extra,
  "Moto-Way": motoway,
  RoadChef: roadchef,
  "Welcome Break": welcomebreak,
};

export { restaurantLogosMap, shopsLogosMap, stationsLogoMap };
